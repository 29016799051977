
import { useEffect,useState } from 'react';
import { useParams,useNavigate } from 'react-router-dom';
import { 
    Chip,
    Container,
    Skeleton,
    Stack
} from '@mui/material';

import { ResponsiveContainer } from '../../../components/responsive';
import { ErrorDisplay } from '../../../components/status';
import { SeoHelmet } from '../../../components/seo';
import { GlossaryContent } from '../components/content';
import { glossaryDataRequest } from '../../../hooks/blog-api';
import { leagueToGlossaryIdMap, leagueConfig } from '../../../config/leagues';


export default function GlossaryPage(){

    const { target } = useParams();
    const navigate = useNavigate();
    
    const [league,setLeague] = useState("NFL");
    const [glossaryContent,setGlossaryContent] = useState(null);
    const [loading,setLoading] = useState(true);
    const [error,setError] = useState(null);

    const handleResponse = (data) => {
        setGlossaryContent(data.content);
        setLoading(false);
    }

    const handleError = (error) => {
        setError("Something went wrong fetching glossary");
        setLoading(false);
    }

    useEffect(() => {   
        setLeague(target);
        if (!(target in leagueToGlossaryIdMap)){
            setError('Glossary not found');
            setLoading(false);
        } else {
            setError(null);
            glossaryDataRequest(leagueToGlossaryIdMap[target],handleResponse,handleError)
        }
        
    }, [target]);

    return (
        <>
        <SeoHelmet 
        title="Glossary"
        description="Statistic definitions for all available stats"
        />
        <ResponsiveContainer>
            <Container maxWidth="md" sx={{backgroundColor: "background.paper", borderRadius: 2, p: 3, boxShadow: 2}}>
                <Stack direction="row" spacing={1} sx={{mb: 3}} width="100%">
                    {leagueConfig.map((leagueInfo,index) => (
                        <Chip 
                        key={index}
                        variant={leagueInfo.target === league ? "filled" : "outlined"}
                        label={leagueInfo.name}
                        color="primary"
                        clickable
                        onClick={() => navigate(`/glossary/${leagueInfo.target}`)}
                        sx={{fontSize: {xs: "small",md:"large"}, p: {xs: 0.2,md: 1} }}
                        />
                    ))}
                </Stack>

                {!loading && error !== null && <ErrorDisplay error={error}/>}

                {loading ? 
                <Skeleton height={500} width={"100%"} variant="rounded" />
                :
                <GlossaryContent
                glossaryContent={glossaryContent}
                />
                }
            </Container>
        </ResponsiveContainer>
        </>
    );
}