import { useNavigate } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import { useState,useEffect } from 'react';

import {
    Box,
    Button,
    Grid,
    Stack,
    TextField,
    Typography
} from '@mui/material';

import CheckCircleIcon from '@mui/icons-material/CheckCircle';

import { formInputStyle } from '../../../style/forms';
import { ErrorDisplay, SuccessDisplay } from '../../../components/status';
import { accountResetPasswordRequest, accountResetTokenValidRequest } from '../../../hooks/api/account';
import { TogglePasswordAdornment } from '../../../components/buttons';
import {MIN_PASSWORD_LENGTH , MAX_PASSWORD_LENGTH } from '../../../config/account'

export function ResetPasswordForm({token}){

    const theme = useTheme();
    const navigate = useNavigate();

    const invalidTokenMessage = "Unique token timed out, request new email to try again";

    const [formData, setFormData] = useState(
        {
            new_password: '',
            new_password_confirmation: '',
        }
    );
    const [passwordsMatch, setPasswordsMatch] = useState(false);
    const [error, setError] = useState(null);
    const [successfulPasswordUpdate,setSuccessfulPasswordUpdate] = useState(false);
    const [submitDisabled,setSubmitDisabled] = useState(false);

    const [showPassword,setShowPassword] = useState(false);
    const [showConfirmationPassword,setShowConfirmationPassword] = useState(false);
    const [isPasswordLengthValid,setIsPasswordLengthValid] = useState(true); // Set to true to prevent it initially showing

    const handleFormChange = (event) => {

        const field = event.target.id;
        let value = event.target.value;

        // Check if passwords match and if length is valid
        if (field === 'new_password') {
            setPasswordsMatch(value === formData.new_password_confirmation);
            setIsPasswordLengthValid(value.length >= MIN_PASSWORD_LENGTH);
        } else if (field === 'new_password_confirmation') {
            setPasswordsMatch(value === formData.new_password);
        }

        setFormData({ ...formData, [field]: value});
    };

    const handleResetPasswordResponse = (response) => {
        if (response.data.success) {
            setError(null);
            setSuccessfulPasswordUpdate(true);
            sleepAndGoLogin();
        } else if (response.data.timed_out) {
            setError(invalidTokenMessage);
            setSubmitDisabled(true);
        } else {
            setError("Password update not allowed")
        }
    }
    const handleResetPasswordError = (error) => {
        if (error.response) {
            setError(error.response.data.message);
        }
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        const params = {
            token: token,
            new_password: formData.new_password
        };
        accountResetPasswordRequest(params,handleResetPasswordResponse,handleResetPasswordError);
    }

    const sleepAndGoLogin = () => {
        setTimeout(() => {
          navigate('/login')
        }, 2000);
    }

    const handleResetTokenValidResponse = (response) => {
        const local_token_valid = response.data.token_valid;
        if (!local_token_valid) {
            setError(invalidTokenMessage);
            setSubmitDisabled(true);
        }
    }
    const handleResetTokenValidError = (error) => {
        setError("Reset token invalid, most likely timed out");
    }

    useEffect(()=>{
        // Check if token is valid
        const params = {
            token: token
        }
        accountResetTokenValidRequest(params,handleResetTokenValidResponse,handleResetTokenValidError);
    },[])

    return (
        <>
            <Stack width="100%" alignItems="center">
            <Grid container justifyContent="center" maxWidth="sm">
                <Grid item xs={12}>
                    <Box component="form" noValidate sx={{textAlign:"center",width:"100%"}}>
                        <Stack direction="column" spacing={1}>
                            <Typography variant="h5">Enter new Password</Typography>
                            <TextField
                                required
                                fullWidth
                                type={showPassword ? 'text' : 'password'}
                                id="new_password"
                                label="New Password"
                                name="new_pw"
                                autoComplete="off"
                                inputProps={{ style: formInputStyle(theme) }}
                                value={formData.new_password}
                                onChange={handleFormChange}
                                InputProps = {
                                    {
                                      endAdornment: (
                                        <TogglePasswordAdornment 
                                        showPassword={showPassword}
                                        setShowPassword={setShowPassword}
                                        />
                                      ),
                                      minLength: MIN_PASSWORD_LENGTH,
                                      maxLength: MAX_PASSWORD_LENGTH,
                                    }
                                }
                            />
                            <TextField
                                required
                                fullWidth
                                type={showConfirmationPassword ? 'text' : 'password'}
                                id="new_password_confirmation"
                                label="Confirm New Password"
                                name="check_new_pw"
                                autoComplete="off"
                                inputProps={{ style: formInputStyle(theme) }}
                                value={formData.new_password_confirmation}
                                onChange={handleFormChange}
                                InputProps = {
                                    {
                                      endAdornment: (
                                        <TogglePasswordAdornment 
                                        showPassword={showConfirmationPassword}
                                        setShowPassword={setShowConfirmationPassword}
                                        />
                                      ),
                                      minLength: MIN_PASSWORD_LENGTH,
                                      maxLength: MAX_PASSWORD_LENGTH,
                                    }
                                }
                            />
                            {!isPasswordLengthValid && (
                                <Typography display="block" variant="body" color="error" >
                                    Password must be at least {MIN_PASSWORD_LENGTH} characters
                                </Typography>
                            )}

                            {(!passwordsMatch && formData.new_password.length !== 0) &&
                                <Typography variant="body" color="error">
                                    Passwords do not match
                                </Typography>
                            }

                            {error && 
                                <ErrorDisplay error={error}>
                                    {error}
                                </ErrorDisplay>
                            }

                            {successfulPasswordUpdate &&
                                <SuccessDisplay 
                                message={"Successfully updated password, redirecting to login..."} 
                                Icon={CheckCircleIcon}
                                />
                            }

                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                sx={{ mt: 3, mb: 2 }}
                                onClick={handleSubmit}
                                disabled={!passwordsMatch || formData.new_password.length === 0 || submitDisabled}
                            >
                                Confirm
                            </Button>
                        </Stack>
                    </Box>
                </Grid>
            </Grid>
            </Stack>
        </>
    )
}