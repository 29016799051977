

import {useState,useEffect} from 'react';
import {
  Box,
  IconButton,
  DialogContent,
  DialogTitle,
  Divider,
  Stack
} from '@mui/material';

import CloseIcon from '@mui/icons-material/Close';
import ShareIcon from '@mui/icons-material/Share';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';


import { createFileName } from "use-react-screenshot";

import { ResponsiveDialog } from '../../../../components/responsive';
import { GenericTooltip, ResponsiveIconStartButton } from '../../../../components/buttons';
import { SuccessDisplay,ErrorDisplay } from '../../../../components/status';

export function ExportPredictionsButton({setupExportData}){

    return (
      <ResponsiveIconStartButton
        text="Share"
        icon={<ShareIcon />}
        onClick={setupExportData}
        id="predictions-share"
      />
    )
}


export function ExportPredictionsPopup({open,setOpen,blob,format}){

    const [successMessage,setSuccessMessage] = useState(null);
    const [errorMessage,setErrorMessage] = useState(null);
  
    const handleClose = () => {
      setOpen(false);
    }

    useEffect(() => {
      setSuccessMessage(null);
      setErrorMessage(null);
    }, [open]);


    // Copy only supported for png
    const handleCopy = async () => {
      try {
      const fileToCopy = new File(
        [blob],
        `prediction.${format}`,
        {
          type: blob.type,
          lastModified: new Date().getTime()
        }
      )
      navigator.clipboard.write([
        new ClipboardItem({
          [blob.type] : fileToCopy,
        }),
      ]);

      setSuccessMessage("Copied");
      }
      catch {
        setSuccessMessage(null);
        setErrorMessage("Somethig went wrong");
      }
    }

    const handleDownload = ({ name = "predictions", extension = format } = {}) => {

      try {
        // Create blob link to download
        const url = window.URL.createObjectURL(
          new Blob([blob]),
        );
        const a = document.createElement("a");
        a.href = url;
        a.download = createFileName(extension, name);
        a.click();

        setSuccessMessage("Downloaded");
      }
      catch {
        setSuccessMessage(null);
        setErrorMessage("Somethig went wrong");
      }
    }
  
    return (
      <ResponsiveDialog open={open}>
        <IconButton
            onClick={handleClose}
            sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: (theme) => theme.palette.primary.main,
                zIndex: 2
            }}
        >
            <CloseIcon />
        </IconButton>
        <DialogTitle>
          Share {format}
        </DialogTitle>
        <DialogContent>
          <Stack direction="column" spacing={1} width='100%' divider={<Divider/>}>
            <Stack direction="row" spacing={2} width='100%'>

              {format === 'png' &&
              <GenericTooltip
              title="Copy"
              >
                <IconButton onClick={handleCopy}>
                  <ContentCopyIcon/>
                </IconButton>
              </GenericTooltip>
              }

              <GenericTooltip
              title="Download"
              >
                <IconButton onClick={handleDownload}>
                  <FileDownloadIcon />
                </IconButton>
              </GenericTooltip>
            </Stack>

            {/* Status of Action */}

            {successMessage &&
            <SuccessDisplay
            message={successMessage}
            Icon={CheckCircleIcon}
            />
            }
            {errorMessage &&
            <ErrorDisplay
            error={errorMessage}
            />
            }

            {blob !== null && blob.type === 'image/png' &&
            <Box component="img"
            src={ window.URL.createObjectURL(new Blob([blob]),)}
            alt="Predictions"
            />
            }
          </Stack>
        </DialogContent>
        
      </ResponsiveDialog>
    )
  }
