
import { useTheme, styled } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import {
  Box,
  Stack,
  Typography
} from '@mui/material';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import { MobileSportsbookLogo } from '../../../../components/graphics/sportsbook-logo';

const OddsStack = styled(Stack)(({ theme }) => ({
    direction:"column",
    height: "100%",
    width: "100%",
  }));


function BestBetMark({largeScreen}){

    const iconDims = largeScreen ? 15 : 10;
    const absolutePos = largeScreen ? 6 : 2;
    return (
        <Box
            sx={{
                position: 'absolute',
                right: absolutePos,
                top: absolutePos -1
            }}
        >
            <MonetizationOnIcon color="success" sx={{height: iconDims,width: iconDims}}/>
        </Box>
    )
}

function SportsbookMark({sportsbook,largeScreen}){

    const iconDims = largeScreen ? 15 : 10;
    const absolutePos = largeScreen ? 5 : 2;
    return (
        <Box
            sx={{
                position: 'absolute',
                right: absolutePos,
                bottom: absolutePos - 4
            }}
        >
            <MobileSportsbookLogo sportsbook={sportsbook} width={iconDims} />
        </Box>
    )
}


export function TotalBlockDisplay({totals,overHasValue=false,underHasValue=false,bestSportsbookOver=false,bestSportsbookUnder=false,debug=null}) {
    // Logic to account for empty totals and/or one side missing such as a book only having an over.

    const OverOddsBox = (
        totals && Object.keys(totals).length !== 0 && totals.over ?
        <OddsBox point={totals.over.point} price={totals.over.price} 
        hasValue={overHasValue} isTotal={true} totalType='over' 
        bestSportsbook={bestSportsbookOver}
        />
        :
        <OddsBox point={"N/A"} price={""} />
    )

    const UnderOddsBox = (
        totals && Object.keys(totals).length !== 0 && totals.under ?
        <OddsBox point={totals.under.point} price={totals.under.price} 
        hasValue={underHasValue} isTotal={true} totalType='under' 
        bestSportsbook={bestSportsbookUnder}
        />
        :
        <OddsBox point={"N/A"} price={""} />
    )
    

    return (
        <OddsStack spacing={1}>
            {OverOddsBox}
            {UnderOddsBox}
        </OddsStack>
    );
}

export function BestOddsTotalBlockDisplay({best_odds_totals}) {
    // Three fields in within each bet type in best_odds_totals
    // point (float)
    // price (int)
    // sportsbook (list[str])

    const best_sportsbook_over = best_odds_totals.over.sportsbooks ? best_odds_totals.over.sportsbooks[0] : null;
    const best_sportsbook_under = best_odds_totals.under.sportsbooks ? best_odds_totals.under.sportsbooks[0] : null;

    return (
        <>
            {best_odds_totals.over.point ?
                        (
                            <>
                                <OddsStack spacing={1}>
                                    <OddsBox point={best_odds_totals.over.point} price={best_odds_totals.over.price} 
                                        isTotal={true} totalType='over' bestOddsSportsbook={best_sportsbook_over}
                                        />
                                    <OddsBox point={best_odds_totals.under.point} price={best_odds_totals.under.price} 
                                        isTotal={true} totalType='under' bestOddsSportsbook={best_sportsbook_under}
                                        />
                                </OddsStack>
                            </>
                        )
                        : 
                        (
                            <>
                                <OddsStack spacing={1}>
                                    <OddsBox point={"N/A"} price={""} />
                                    <OddsBox point={"N/A"} price={""} />
                                </OddsStack>
                            </>
                        )
                    }
        </>
    );
}

export function SpreadBlockDisplay(
    {spreads,awayHasValue=false,homeHasValue=false,bestSportsbookAway=false,bestSportsbookHome=false}
) {
    // Account for missing spreads and/or one side of the bet missing for a book

    const AwayOddsBox = ( spreads && spreads.away ?
        <OddsBox point={spreads.away.point} price={spreads.away.price} hasValue={awayHasValue} bestSportsbook={bestSportsbookAway} />
        :
        <OddsBox point={"N/A"} price={""} />
    )

    const HomeOddsBox = ( spreads && spreads.home ?
        <OddsBox point={spreads.home.point} price={spreads.home.price} hasValue={homeHasValue} bestSportsbook={bestSportsbookHome} />
        :
        <OddsBox point={"N/A"} price={""} />
    )

    return (
        <OddsStack spacing={1} >
            {AwayOddsBox}
            {HomeOddsBox}
        </OddsStack>
    );
}

export function BestOddsSpreadBlockDisplay({best_odds_spreads}) {

    // Three fields in within each bet type in best_odds_spreads
    // point (float)
    // price (int)
    // sportsbook (list[str])


    // Use first sportsbook in list of sportsbook with best odds for this game if multiple
    const best_sportsbook_away = best_odds_spreads.away.sportsbooks ? best_odds_spreads.away.sportsbooks[0] : null;
    const best_sportsbook_home = best_odds_spreads.home.sportsbooks ? best_odds_spreads.home.sportsbooks[0] : null;

    return (
        <>
            {best_odds_spreads.away.point ? 
                        (
                            <>
                                <OddsStack spacing={1} >
                                    <OddsBox point={best_odds_spreads.away.point} price={best_odds_spreads.away.price} bestOddsSportsbook={best_sportsbook_away}/>
                                    <OddsBox point={best_odds_spreads.home.point} price={best_odds_spreads.home.price} bestOddsSportsbook={best_sportsbook_home} />
                                </OddsStack>
                            </>
                        )
                        : 
                        (
                            <>
                                <OddsStack spacing={1} >
                                    <OddsBox point={"N/A"} price={""} />
                                    <OddsBox point={"N/A"} price={""} />
                                </OddsStack>
                            </>
                        )
                    }
        </>
    );
}

export function OddsBox({point,price,hasValue=false,isTotal=false,totalType="over",bestSportsbook=false,bestOddsSportsbook=null}) {

    const theme = useTheme();
    const largeScreen = useMediaQuery(theme.breakpoints.up('md'));

    let bgColor = theme.palette.background.paper;
    let fontColor = 'default';
    if (hasValue) {
        bgColor = theme.palette.success.dark;
    }

    if (point > 0 && !isTotal) {
        point = "+" + point;
    }
    else if (point === 0) {
        point = "PK";
    }
    else if (point === "N/A") {
        bgColor = theme.palette.background.missing;
        fontColor = theme.palette.primary.missing;
    }

    if (price > 0) {
        price = "+" + price;
    }

    if (isTotal) {
        if (totalType === "over") {
            point = "o" + point;
        }
        else if (totalType === "under") {
            point = "u" + point;
        }
    }

    return (
        <>
            <Stack direction="column" alignItems="center" justifyContent="center"
            sx={{
                backgroundColor: bgColor,
                borderRadius: 2,
                p: 1,
                width: "100%",
                height: "50%",
                minHeight: {xs:40,sm:52},
                boxShadow: 2,
                position: 'relative' // need this for BestBetMark to work
            }}>

                <Box component="span">
                    <Typography variant={largeScreen ?"body1":"body2"} color={fontColor} >
                        {point}
                    </Typography>
                </Box>
                {price ? 
                (
                    <>
                        <Box component="span" sx={{mt:-1}}>
                            <Typography variant="caption">
                                {price}
                            </Typography>
                        </Box>
                    </>
                )
                :
                (
                    <>
                    </>
                )    
                }
                
                {bestSportsbook && <BestBetMark largeScreen={largeScreen}/>}
                {bestOddsSportsbook && <SportsbookMark sportsbook={bestOddsSportsbook} largeScreen={largeScreen}/>}
            </Stack>
        </>
    )
}