
import {useMemo,useState,useEffect} from 'react';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import {
  Box,
  Checkbox,
  FormControlLabel,
  MenuItem,
  Stack,
  Typography
} from '@mui/material';
import ShareIcon from '@mui/icons-material/Share';
import CloseIcon from '@mui/icons-material/Close';
import LoadingButton from '@mui/lab/LoadingButton';

import dayjs from 'dayjs';
import html2canvas from 'html2canvas';

import { TeamLogo } from '../../../../components/graphics/team-logo';
import { ResponsiveSportsbookLogo } from '../../../../components/graphics/sportsbook-logo';

import { 
    TotalBlockDisplay,SpreadBlockDisplay, 
    BestOddsTotalBlockDisplay, 
    BestOddsSpreadBlockDisplay 
} from '../common/prediction-displays';

import { 
    BasePredictionResultsMRT,
    DesktopOddsColumnWidth,
    MobileOddsColumnWidth,
    tableHeight,
    validMarkets,
    validSportsbooks 
} from '../../../../components/table';

import { FilterMenu, SortByMenu } from '../../../../components/filter-menu';
import { SingleSelect } from '../../../../components/menus';
import {descendingSortFunction,ascendingSortFunction} from '../../../../utils/sorting';
import { ExportPredictionsButton, ExportPredictionsPopup } from '../common/export-predictions';
import { ResponsiveIconStartButton } from '../../../../components/buttons';
import { WarningSnackbar } from '../../../../components/snackbars';
import { blobToShareData, filterPredictionsExportData, jsonToCsvBlob } from '../../../../utils/export';
import { availablePredictionExportDataFormats, defaultPredictionExportDataFormat } from '../../../../config/export';
import { dropdownHeight, toolbarDropdownHeight } from '../common/config';

function MarketSelectionDropdown({marketSelection,onChangeFn,width=null,height=null}){

    return (
        <SingleSelect 
            label="Market"
            value={marketSelection}
            availableValues={validMarkets.map(value => value.key)}
            availableValuesDisplay={validMarkets.map(value => value.display)}
            onChangeFn={onChangeFn}
            width={width}
            height={height}
        />
    )
}

function TeamPredictionResultsFilter(
    {marketSelection,sportsbookSelection,setSportsbookSelection,
    largeScreen,handleColumnVisibilityToggle,
    predictionData,setFilteredPredictionsData}
){

    ////////////////////////////////////////////////////////
    // Filters
    // Note: Market and Sportsbook filters are tied into the
    // table and can not be moved into this component
    ////////////////////////////////////////////////////////
    const [showValueBetsOnly,setShowValueBetsOnly] = useState(false);
    const valueFilterFn = ({predicted_odds_values}) => showValueBetsOnly ? predicted_odds_values[marketSelection].max_value > 0 : true;

    const availableSideTypes = ["All","Favorite","Underdog"];
    const [sideType,setSideType] = useState("All");
    const sideTypeFilterFn = ({predicted_odds,best_odds}) => {
        const favorite = predicted_odds.spreads.away.point < 0 ? 'away' : 'home';
        const underdog = favorite === 'away' ? 'home' : 'away';

        if (sideType === 'All') {
            return true;
        }
        else {
            const sideTypeFilter = sideType === 'Favorite' ? favorite : underdog;
            return predicted_odds.spreads[sideTypeFilter].point < best_odds.spreads[sideTypeFilter].consensus
        }
    }

    const availableTotalTypes = ["All","Over","Under"];
    const [totalType,setTotalType] = useState("All");
    const totalTypeFilterFn = ({predicted_odds,best_odds}) => {
        if (totalType === 'All') {
            return true;
        }
        else {
            if (totalType === 'Over') {
                const totalTypeFilter = 'over';
                return predicted_odds.totals[totalTypeFilter].point > best_odds.totals[totalTypeFilter].consensus
            } else {
                // Unders
                const totalTypeFilter = 'under';
                return predicted_odds.totals[totalTypeFilter].point < best_odds.totals[totalTypeFilter].consensus
            }
        }
    }

    // If columns change, we need to reapply filters
    useEffect(() => {
        if (predictionData.length !== 0) {
            let filteredData = predictionData.slice().filter(valueFilterFn);
            filteredData = filteredData.filter(sideTypeFilterFn);
            filteredData = filteredData.filter(totalTypeFilterFn);
            setFilteredPredictionsData(filteredData);
        }
     },[predictionData,marketSelection,showValueBetsOnly,sideType,totalType]);


    // Always reset the type filters on market switch
     useEffect(() => {
        setSideType("All");
        setTotalType("All");
     },[marketSelection]);
     
    return (
        <>
            <FilterMenu>
                {/* The below items are only shown on small screens */}
                {!largeScreen &&
                <MenuItem>
                    <MarketSelectionDropdown marketSelection={marketSelection} onChangeFn={handleColumnVisibilityToggle} />
                </MenuItem>
                }
                {!largeScreen &&
                <MenuItem>
                    <SingleSelect 
                    label="Sportsbook"
                    value={sportsbookSelection}
                    availableValues={['best_odds'].concat(validSportsbooks.map(value => value.key))}
                    availableValuesDisplay={['Best Odds'].concat(validSportsbooks.map(value => value.display))}
                    onChangeFn={(event) => {setSportsbookSelection(event.target.value)}}
                    />
                </MenuItem>
                }

                {/* The below items are always shown in the filter */}
                <MenuItem>
                    <FormControlLabel
                        control={
                        <Checkbox
                            checked={showValueBetsOnly}
                            onChange={(event) => setShowValueBetsOnly(event.target.checked)}
                            inputProps={{ 'aria-label': 'controlled' }}
                        />
                        }
                    label="Show value bets only"
                    />
                </MenuItem>
                        
                {marketSelection === 'spreads' &&
                <MenuItem>
                    <SingleSelect
                    label="Bet Type"
                    value={sideType}
                    onChangeFn={(event) => setSideType(event.target.value)}
                    availableValues={availableSideTypes}
                    />
                </MenuItem>
                }

                {marketSelection === 'totals' &&
                <MenuItem>
                    <SingleSelect
                    label="Bet Type"
                    value={totalType}
                    onChangeFn={(event) => setTotalType(event.target.value)}
                    availableValues={availableTotalTypes}
                    />
                </MenuItem>
                }
            </FilterMenu>
        </>
    )
}

function TeamPredictionResultsSortBy({filteredData,setFilteredData,marketSelection}){

        ////////////////////////////////////////////////////////
        // Sort functions
        ////////////////////////////////////////////////////////
    
        const options = [
            {
                title: "Highest Value",
                sortFn: (a,b) => 
                    descendingSortFunction(a.predicted_odds_values[marketSelection].max_value,b.predicted_odds_values[marketSelection].max_value)
            },
            {
                title: "Lowest Value",
                sortFn: (a,b) => 
                    ascendingSortFunction(a.predicted_odds_values[marketSelection].max_value,b.predicted_odds_values[marketSelection].max_value)
            },
        ];
        
        // Set selected index to null. Not sorting anything to start.
        const [selectedIndex,setSelectedIndex] = useState(null);
        
        const sortData = (sortFn) => {
            const localFilteredData = filteredData.slice().sort(sortFn);
            setFilteredData(localFilteredData);
        }


        // If columns change, we need to reapply sorting
        useEffect(() => {
            if (selectedIndex !== null) {
                sortData(options[selectedIndex].sortFn);
            }
         },[marketSelection]);

        const handleChange = (event) => {
            const index = event.currentTarget.value;
            sortData(options[index].sortFn);
            setSelectedIndex(index);
        }
    
        return (
            <SortByMenu
            selectedIndex={selectedIndex}
            options={options}
            onChangeFn={handleChange}
            />
        )
}



export function TeamPredictionResultsTable({league,date,predictionData,loading}){

    const theme = useTheme();
    const largeScreen = useMediaQuery(theme.breakpoints.up('md'));
    const screenTooSmallForShare = useMediaQuery('(max-width:350px)');

    const columnVisibilityDict = {
        "spreads" : {
            "predicted_spreads" : true,
            "best_odds_spreads"  : true,
            "fanduel_spreads"   : true,
            "draftkings_spreads" : true,
            "betmgm_spreads"    : true,
            "espnbet_spreads"   : true,
            "betrivers_spreads"   : true,
            "betonlineag_spreads"   : true,
            "bovada_spreads"     : true,
            "ballybet_spreads"   : true,
            "hardrockbet_spreads" : true,
            "mybookieag_spreads"  : true,
            "caesars_spreads"   : true,
            "betus_spreads"   : true,
            "lowvigag_spreads"   : true,
            "betparx_spreads"   : true,
            
            "predicted_totals"  : false,
            "best_odds_totals"  : false,
            "fanduel_totals"   : false,
            "draftkings_totals" : false,
            "betmgm_totals"    : false,
            "espnbet_totals"   : false,
            "betrivers_totals"   : false,
            "betonlineag_totals"   : false,
            "bovada_totals"     : false,
            "ballybet_totals"   : false,
            "hardrockbet_totals" : false,
            "mybookieag_totals"  : false,
            "caesars_totals"   : false,
            "betus_totals"   : false,
            "lowvigag_totals"   : false,
            "betparx_totals"   : false,
            
            
        },
        "totals" : {
            "predicted_spreads" : false,
            "best_odds_spreads"  : false,
            "fanduel_spreads"   : false,
            "draftkings_spreads" : false,
            "betmgm_spreads"    : false,
            "espnbet_spreads"   : false,
            "betrivers_spreads"   : false,
            "betonlineag_spreads"   : false,
            "bovada_spreads"     : false,
            "ballybet_spreads"   : false,
            "hardrockbet_spreads" : false,
            "mybookieag_spreads"  : false,
            "caesars_spreads"   : false,
            "betus_spreads"   : false,
            "lowvigag_spreads"   : false,
            "betparx_spreads"   : false,
            
            "predicted_totals"  : true,
            "best_odds_totals"  : true,
            "fanduel_totals"   : true,
            "draftkings_totals" : true,
            "betmgm_totals"    : true,
            "espnbet_totals"   : true,
            "betrivers_totals"   : true,
            "betonlineag_totals"   : true,
            "bovada_totals"     : true,
            "ballybet_totals"   : true,
            "hardrockbet_totals" : true,
            "mybookieag_totals"  : true,
            "caesars_totals"   : true,
            "betus_totals"   : true,
            "lowvigag_totals"   : true,
            "betparx_totals"   : true,
            
        }
    }

    ////////////////////////////////////////////////////////
    // States
    ////////////////////////////////////////////////////////
    const [filteredPredictionsData,setFilteredPredictionsData] = useState(predictionData);

    const [marketSelection,setMarketSelection] = useState("spreads");
    const [sportsbookSelection,setSportsbookSelection] = useState("best_odds");
    const [columnVisibility,setColumnVisibility] = useState(columnVisibilityDict[marketSelection]);

    // Share States - I know there is a lot
    const [tableRef,setTableRef] = useState(null);
    const [enableExport,setEnableExport] = useState(false);
    const [rowSelection,setRowSelection] = useState({});
    const [enableRowSelection,setEnableRowSelection] = useState(false);
    const [exportLoading,setExportLoading] = useState(false);
    const [sharePopupOpen,setSharePopupOpen] = useState(false);
    const [exportDataBlob,setExportDataBlob] = useState(null);
    const [warningSnackbarOpen,setWarningSnackbarOpen] = useState(false);
    const [preShareFilteredPredictionsData,setPreShareFilteredPredictionsData] = useState([]);
    const [exportDataFormat,setExportDataFormat] = useState(defaultPredictionExportDataFormat);

    const handleColumnVisibilityToggle = (event) => {
        setMarketSelection(event.target.value);

        updateColumnVisibilityResponsive(event.target.value);
    }

    const updateColumnVisibilityResponsive = (localMarketSelection) => {
        if (largeScreen) {
            setColumnVisibility(columnVisibilityDict[localMarketSelection]);
        }
        else {
            let localColumnVisibility = columnVisibilityDict[localMarketSelection];
            for (const [key, value] of Object.entries(localColumnVisibility)) {
                if (!key.includes('predicted') && !key.includes(sportsbookSelection)){
                    localColumnVisibility[key] = false;
                }
            }
            setColumnVisibility(localColumnVisibility);
        }
    }

    ////////////////////////////////////////////////////////
    // Column Definitions
    ////////////////////////////////////////////////////////
    const oddsColumnWidth = largeScreen ? DesktopOddsColumnWidth : MobileOddsColumnWidth;

    const columns = useMemo(
      () => [
        {
            // Set the row value for search, display is overriden by Cell below
            accessorFn: (row) => row.teams.away_display_name + " " + row.teams.home_display_name, 
            id: 'teams',
            header: 'Games',
            size: largeScreen ? 225 : 150,
            enableSorting: false,
            enableColumnFilter: false,
            enableColumnPinning: true,
            Header: ({ column }) => (
                <>
                    <Typography variant={largeScreen ? "body1" : "body2"}>
                        {dayjs(date).format('MMM Do, YYYY')}
                    </Typography>
                </>
            ),
            Cell: ({ row }) =>
                <>
                    <GamesDisplay teams={row.original.teams} league={league} largeScreen={largeScreen} />
                </>
        },
        {
            accessorFn: (row) => row.predicted_odds.spreads, 
            id: 'predicted_spreads',
            header: 'Prediction',
            size: oddsColumnWidth,
            enableSorting: false,
            enableColumnFilter: false,
            enableColumnPinning: true,
            Header: ({ column }) => (
                <>
                    <Typography variant={largeScreen ? "h6" : "caption"}>{column.columnDef.header}</Typography>
                </>
            ),
            Cell: ({ row }) =>
                <>
                    <SpreadBlockDisplay spreads={row.original.predicted_odds.spreads} 
                        awayHasValue={row.original.predicted_odds_values.spreads.away.value_bool}
                        homeHasValue={row.original.predicted_odds_values.spreads.home.value_bool}
                    />
                </>
        },
        {
            accessorFn: (row) => row.predicted_odds.totals, 
            id: 'predicted_totals',
            header: 'Prediction',
            size: oddsColumnWidth,
            enableSorting: false,
            enableColumnFilter: false,
            enableColumnPinning: true,
            Header: ({ column }) => (
                <>
                    <Typography variant={largeScreen ? "h6" : "body2"}>{column.columnDef.header}</Typography>
                </>
            ),
            Cell: ({ row }) =>
                <>
                    <TotalBlockDisplay totals={row.original.predicted_odds.totals} 
                        overHasValue={row.original.predicted_odds_values.totals.over.value_bool} 
                        underHasValue={row.original.predicted_odds_values.totals.under.value_bool}
                    />
                </>
        },
        {
            accessorFn: (row) => row.best_odds, 
            id: 'best_odds_spreads',
            header: 'Best Odds',
            size: oddsColumnWidth,
            enableSorting: false,
            enableColumnFilter: false,
            enableColumnPinning: false,
            Header: ({ column }) => (
                  <>
                      <Typography variant={largeScreen ? "h6" : "caption"}>{column.columnDef.header}</Typography>
                  </>
              ),
            Cell: ({ row }) =>
                <>
                    <BestOddsSpreadBlockDisplay best_odds_spreads={row.original.best_odds.spreads} />
                </>
        },
        {
            accessorFn: (row) => row.best_odds, 
            id: 'best_odds_totals',
            header: 'Best Odds',
            size: oddsColumnWidth,
            enableSorting: false,
            enableColumnFilter: false,
            enableColumnPinning: false,
            Header: ({ column }) => (
                  <>
                      <Typography variant={largeScreen ? "h6" : "caption"}>{column.columnDef.header}</Typography>
                  </>
              ),
            Cell: ({ row }) =>
                <>
                    <BestOddsTotalBlockDisplay best_odds_totals={row.original.best_odds.totals} />
                </>
        },
        ...validSportsbooks.map((sportsbook,index) => (
            {
            accessorFn: (row) => row.sportsbooks[sportsbook.display].spreads, 
            id: `${sportsbook['key']}_spreads`,
            header: sportsbook.key,
            enableSorting: false,
            enableColumnFilter: false,
            enableColumnPinning: false,
            size: oddsColumnWidth,
            Header: ({ column }) => (
                <>
                    <ResponsiveSportsbookLogo sportsbook={column.columnDef.header} desktopWidth={"100%"} mobileWidth={30} largeScreen={largeScreen} />
                </>
            ),
            Cell: ({ row }) => (
                <>
                    <SpreadBlockDisplay spreads={row.original.sportsbooks[sportsbook.display].spreads} 
                    bestSportsbookAway={row.original.best_odds.spreads.away.sportsbooks.includes(sportsbook.display)}
                    bestSportsbookHome={row.original.best_odds.spreads.home.sportsbooks.includes(sportsbook.display)}
                    />
                </>
            )
            }
        ))
        ,
        ...validSportsbooks.map((sportsbook,index) => (
            {
            accessorFn: (row) => row.sportsbooks[sportsbook.display].totals, 
            id: `${sportsbook['key']}_totals`,
            header: sportsbook.key,
            enableSorting: false,
            enableColumnFilter: false,
            enableColumnPinning: false,
            size: oddsColumnWidth,
            Header: ({ column }) => (
                <>
                    <ResponsiveSportsbookLogo sportsbook={column.columnDef.header} desktopWidth={"100%"} mobileWidth={30} largeScreen={largeScreen} />
                </>
            ),
            Cell: ({ row }) => (
                <>
                    <TotalBlockDisplay totals={row.original.sportsbooks[sportsbook.display].totals} 
                    bestSportsbookOver={row.original.best_odds.totals.over.sportsbooks.includes(sportsbook.display)}
                    bestSportsbookUnder={row.original.best_odds.totals.under.sportsbooks.includes(sportsbook.display)}
                    />
                </>
            )
            }
        ))

        ],
        [largeScreen]
    );

    useEffect(() => {
        updateColumnVisibilityResponsive(marketSelection);
    }, [largeScreen,marketSelection,sportsbookSelection]);

    ////////////////////////////////////////////////////////
    // Share functions
    ////////////////////////////////////////////////////////

    const setupExportData = (table) => {
        // Sets up table to allow users to select the rows they
        // want to export

        if (screenTooSmallForShare) {
            setWarningSnackbarOpen(true);
            return;
        }

        setTableRef(table);
        setEnableExport(true);
        setPreShareFilteredPredictionsData(filteredPredictionsData);
        setEnableRowSelection(true);
    }

    const exportData = async (shareData) => {
        // Only use Web Share API when mobile
        if (navigator.share !== null && !largeScreen) {
            try {
              await navigator
                .share(shareData)
                .then(() => {
                  setEnableRowSelection(true);
                  setFilteredPredictionsData(preShareFilteredPredictionsData);
                  setExportLoading(false);
              });
            } catch (error) {
              // Web Share Api exited with bad status. This occurs
              // when a share is cancelled though
              setEnableRowSelection(true);
              setFilteredPredictionsData(preShareFilteredPredictionsData);
              setExportLoading(false);
            }
        } else {
              // fallback code for unsupported browsers and desktop browsers
              setSharePopupOpen(true);
  
              setEnableRowSelection(true);
              setFilteredPredictionsData(preShareFilteredPredictionsData);
              setExportLoading(false);
        }

    }

    const sharePng = async (canvas) => {

        const imageUrl = canvas.toDataURL();
        const blob = await (await fetch(imageUrl)).blob();
        setExportDataBlob(blob);
        const shareData = blobToShareData(blob,'predictions.png');

        exportData(shareData);
        
    };

    const shareCsv = async (selectedRowIndexes) => {

        // Convert filtered predictions data into a csv
        let rowsToExport = filteredPredictionsData.slice().filter((_,index) => selectedRowIndexes.includes(index.toString()));

        // Remove some data before export to make data consistent length
        // and to protect some of the information from being easily exported.
        rowsToExport = filterPredictionsExportData(rowsToExport,true);

        const blob = jsonToCsvBlob(rowsToExport)
        setExportDataBlob(blob);
        const shareData = blobToShareData(blob,'predictions.csv');

        exportData(shareData);
    };

    const handleExportData = () => {
        // Do not try to export nothing
        if (Object.keys(rowSelection).length == 0) {
            return;
        }

        setExportLoading(true);

        // Set up table for canvas capture
        // Filter to show only selected rows and disable row selection to hide that column
        // and styling
        const selectedRowIndexes = Object.keys(rowSelection);
        setFilteredPredictionsData(filteredPredictionsData.slice().filter((_,index) => selectedRowIndexes.includes(index.toString())));
        setRowSelection({});
        setEnableRowSelection(false);

        switch (exportDataFormat) {
            case "png":
                // Wait while canvas setup is rendering
                setTimeout(() => {

                const ref = tableRef.refs.tableContainerRef.current;
                
                html2canvas(ref,{
                    height: document.outerHeight,
                    windowHeight: document.outerHeight,
                    scale: 5,
                    useCORS: true
                }).then(canvas => {
                    sharePng(canvas);
                });

                }, 1000);
                break;

            case "csv":
                shareCsv(selectedRowIndexes);
                break;

            default:
                // Should never occur
                break;

        }
        
    }

    const handleCancelExportData = () => {
        setRowSelection({});
        setEnableExport(false);
        setEnableRowSelection(false);
    }

    return (
    <>
      <Box component='div' sx={{width: largeScreen ? '90%' : null}}>
        <BasePredictionResultsMRT

            columns={columns}
            data={filteredPredictionsData}

            loading={loading}
            theme={theme}
            largeScreen={largeScreen}
            height={enableExport ? 'none' : tableHeight}

            // Top Toolbar custom actions
            renderTopToolbarCustomActions={({ table }) => {

                return (
                <Box component="div" sx={{ display: 'flex', gap: '0.5rem'}}>
                    <ExportPredictionsPopup 
                    open={sharePopupOpen} 
                    setOpen={setSharePopupOpen}
                    blob={exportDataBlob}
                    format={exportDataFormat}
                    />
                    <WarningSnackbar 
                    open={warningSnackbarOpen}
                    setOpen={setWarningSnackbarOpen}
                    message={"Screen too small to share predictions"} 
                    />
                    {enableExport ?
                    <>
                    <LoadingButton
                    loading={exportLoading}
                    startIcon={<ShareIcon />}
                    onClick={handleExportData} 
                    variant="contained"
                    sx={{backgroundColor: "success.dark"}}
                    >
                        Share
                    </LoadingButton>
                    <SingleSelect
                    label="format"
                    value={exportDataFormat}
                    availableValues={availablePredictionExportDataFormats}
                    onChangeFn={(event) => setExportDataFormat(event.target.value)}
                    width={80}
                    height={toolbarDropdownHeight}
                    />
                    <ResponsiveIconStartButton
                    text="Cancel"
                    icon={<CloseIcon />}
                    onClick={handleCancelExportData} 
                    sx={{backgroundColor: "error.dark"}}
                    />
                    </>
                    :
                    <>
                    {largeScreen && 
                    // The market selection dropdown is moved into the Filter
                    // for small screens
                    <MarketSelectionDropdown 
                    marketSelection={marketSelection} 
                    onChangeFn={handleColumnVisibilityToggle} 
                    width={150}
                    height={toolbarDropdownHeight}
                    />
                    }
                    <TeamPredictionResultsFilter 
                        marketSelection={marketSelection}
                        sportsbookSelection={sportsbookSelection}
                        setSportsbookSelection={setSportsbookSelection}
                        largeScreen={largeScreen}
                        handleColumnVisibilityToggle={handleColumnVisibilityToggle}
                        predictionData={predictionData}
                        setFilteredPredictionsData={setFilteredPredictionsData}
                    />
                    <TeamPredictionResultsSortBy 
                        filteredData={filteredPredictionsData}
                        setFilteredData={setFilteredPredictionsData}
                        marketSelection={marketSelection}
                    />
                    <ExportPredictionsButton setupExportData={() => setupExportData(table)} />
                    </>
                    }
                </Box>
                );
            }}
            
            state={{ columnVisibility, showSkeletons: loading, showGlobalFilter: true, rowSelection }}
            
            onColumnVisibilityChange={ setColumnVisibility }

            // Column Pinning
            enableColumnPinning={false}
            layoutMode='grid-no-grow'
            initialState= {{
                columnPinning: { left: ['mrt-row-select','teams','predicted_spreads','predicted_totals'] },
                showGlobalFilter: true
            }}

            // Row selection
            enableRowSelection={enableRowSelection}
            onRowSelectionChange={setRowSelection}

          /> {/* End of table definition */}

        </Box>
    </>
    )
}


function GamesDisplay({teams,league,largeScreen}) {

    const typographyVariant = largeScreen ? "body1": "body2";
    const logoWidth = largeScreen ? 25 : 20;
    const innerStackSpacing = largeScreen ? 1 : 0.75;

    return (
        <>
            <Stack direction="column" height="100%" width="100%" justifyContent="center" spacing={1}>
                <Stack height="50%" width="100%" justifyContent="center">
                    <Stack direction="row" width="100%" alignItems="center" spacing={innerStackSpacing}>
                        <TeamLogo team={teams.away} league={league} width={logoWidth}/>
                        <Typography variant={typographyVariant}>
                            {largeScreen ? teams.away_display_name : teams.away_display_name_abbreviated}
                        </Typography>
                        <Box sx={{flexGrow: 1}} />
                        <Typography variant={typographyVariant}>
                            {teams.away_prediction}
                        </Typography>
                    </Stack>
                </Stack>
                
                <Stack height="50%" width="100%" justifyContent="center">
                    <Stack direction="row" width="100%" alignItems="center" spacing={innerStackSpacing}>
                        <TeamLogo team={teams.home} league={league} width={logoWidth}/>
                        <Typography variant={typographyVariant}>
                            {largeScreen ? teams.home_display_name : teams.home_display_name_abbreviated}
                        </Typography>
                        <Box sx={{flexGrow: 1}} />
                        <Typography variant={typographyVariant}>
                            {teams.home_prediction}
                        </Typography>
                    </Stack>
                    
                </Stack>
            </Stack>
        </>
    )
}