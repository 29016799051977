import {
    MRT_GlobalFilterTextField,
    MaterialReactTable,
    useMaterialReactTable 
}
from 'material-react-table';

import {
    Box
  } from '@mui/material';

export const tableHeight = 600;


const defaultMRTProps = {

    enablePagination: false,

    // Loading animation
    muiSkeletonProps:{
        variant: "text",
        animation: "wave",
    },

    enableTopToolbar: true,
    enableBottomToolbar: false,
    enableDensityToggle: false,
    enableFullScreenToggle: false,
    enableHiding: false,

    // Table Alert Banner
    positionToolbarAlertBanner:'none',
    
    enableRowVirtualization: true,
}

export function StatSelectionMRT ({
    columns,data,rowSelection,setRowSelection,loading,height,isMobile,theme,...props
})
{
    const table = useMaterialReactTable({
        ...defaultMRTProps,

        columns: columns,
        data: data,

        // Table style
        muiTablePaperProps: {
            elevation: isMobile? 0 : 1, //mui box shadow
            sx:{height: height}
        },

        // Table Styling
        muiTableContainerProps: { sx: { maxHeight: height-70} },

        // Header & Column Headers
        // muiTableHeadCellProps applies to all column headers
        // displayColumnDefOptions are for built-in mrt columns
        enableStickyHeader: true,
        muiTableHeadCellProps: {
        sx: isMobile ? {
            color: 'transparent',
            pl: 1
        }:
        {
            fontWeight: 'bold',
            fontSize: '14px',
            borderColor: '#000',
            borderBottom: 2,
        }
        },

        // Can be defined even if no row select
        displayColumnDefOptions: { 
        'mrt-row-select': {
            size: isMobile ? 5 : 10,
        },
        },

        muiTableBodyCellProps: {
        sx: isMobile &&
            {
            p: 1,
            borderBottom: 'none',
            width: '100%',
            boxShadow: 0,
            backgroundColor: theme.palette.background.paper,
            fontSize: 'large'
            }
        },

        muiTableBodyRowProps: ({ row }) => ({
        onClick: row.getToggleSelectedHandler(),
        sx: isMobile ?
        {
            flexDirection: 'column',
            border: 0,
            pb: 1
        }
        :
        {
            cursor: 'pointer'
        }
        }),

        // Filters, Sorting, and Search
        // These are defaults and can be overriden by props
        enableColumnActions: false,
        enableColumnFilters: true,
        enableSorting:false,
        enableGlobalFilter: true,
        positionGlobalFilter: 'right',

        // Row selection
        enableRowSelection: true,
        onRowSelectionChange: setRowSelection,
        state: { rowSelection, showSkeletons: loading },

        ...props

    })

    return (
        <>
        <MaterialReactTable
            table={table}
        />
        </>
    )
}

export const validMarkets = [
    {
        "key" : "spreads",
        "display" : "Spreads"
    },
    {
        "key" : "totals",
        "display" : "Totals"
    },
]

export const validSportsbooks = [
    {
        "key" : "fanduel",
        "display" : "FanDuel"
    },
    {
        "key" : "draftkings",
        "display" : "DraftKings"
    },
    {
        "key" : "betmgm",
        "display" : "BetMGM"
    },
    {
        "key" : "espnbet",
        "display" : "ESPN BET"
    },
    {
        "key" : "betrivers",
        "display" : "BetRivers"
    },
    {
        "key" : "betonlineag",
        "display" : "BetOnline.ag"
    },
    {
        "key" : "bovada",
        "display" : "Bovada"
    },
    {
        "key" : "ballybet",
        "display" : "Bally Bet"
    },
    {
        "key" : "hardrockbet",
        "display" : "Hard Rock Bet"
    },
    {
        "key" : "mybookieag",
        "display" : "MyBookie.ag"
    },
    {
        "key" : "caesars",
        "display" : "Caesars"
    },
    {
        "key" : "betus",
        "display" : "BetUS"
    },
    {
        "key" : "lowvigag",
        "display" : "LowVig.ag"
    },
    {
        "key" : "betparx",
        "display" : "betPARX"
    },
]

export const DesktopOddsColumnWidth = 130;
export const MobileOddsColumnWidth = 80;

export function BasePredictionResultsMRT({
    columns,data,rowSelection,setRowSelection,loading,theme,largeScreen,height,...props
})
{

    const table = useMaterialReactTable({
        ...defaultMRTProps,

        columns: columns,
        data: data,

        // Table style
        muiTablePaperProps: {
            elevation: 1, //mui box shadow
        },

        // Table Styling
        muiTableContainerProps: { sx: { maxHeight: height } },

        // Header & Column Headers
        // muiTableHeadCellProps applies to all column headers
        // displayColumnDefOptions are for built-in mrt columns
        enableStickyHeader: true,

        // Filters, Sorting, and Search
        // These are defaults and can be overriden by props
        enableColumnActions: false,
        enableColumnFilters: false,
        enableSorting: false,
        enableGlobalFilter: true,
        positionGlobalFilter: largeScreen ? 'right' : 'none',

        mrtTheme:{
            baseBackgroundColor: theme.palette.background.default
        },

        // Can be defined even if no row select
        displayColumnDefOptions: { 
            'mrt-row-select': {
                size: largeScreen ? 10 : 5,
            },
        },

        // Header & Column Headers
        // muiTableHeadCellProps applies to all column headers
        // displayColumnDefOptions are for built-in mrt columns}
        muiTableHeadCellProps:{
            sx: {
            fontSize: '18px',
            borderBottom: 0,
            boxShadow: 0,
            backgroundColor: theme.palette.background.default,
            height: 60,
            justifyContent: 'center',
            p: largeScreen ? null : 0,
            '& .Mui-TableHeadCell-Content' : {
                justifyContent: 'center',
                textAlign: 'center'
            }
            },
        },

        muiTableHeadRowProps:{
            sx: {
            boxShadow: 0,
            }
        },

        muiTableBodyCellProps:{
            sx: {
                backgroundColor: theme.palette.background.default, // Overriding defaulttranslucence on column pinning
                boxShadow: 0,
                p: largeScreen ? 1 : 0.5
            }
        },


        // Filters, Sorting, and Search
        enableColumnFilters: false,
        globalFilterFn:'contains', // default is fuzzy

        // Row selection
        enableRowSelection: false,

        ...props

    });
    return (
        <>
        {!largeScreen &&
        <Box sx={{display: 'flex', p: 1}}>
            <MRT_GlobalFilterTextField table={table} />
        </Box>
        }

        <MaterialReactTable
            table={table}
        />
        </>
    )
}