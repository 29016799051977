
import {useMemo,useState,useEffect} from 'react';
import { useTheme, } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import {
  Box,
  Checkbox,
  FormControlLabel,
  MenuItem,
  Stack,
  Typography
} from '@mui/material';
import ShareIcon from '@mui/icons-material/Share';
import CloseIcon from '@mui/icons-material/Close';
import LoadingButton from '@mui/lab/LoadingButton';

import dayjs from 'dayjs';
import html2canvas from 'html2canvas';

import { 
    BasePredictionResultsMRT,
    DesktopOddsColumnWidth,
    MobileOddsColumnWidth,
    tableHeight,
    validSportsbooks 
} from '../../../../components/table';

import { TeamLogo } from '../../../../components/graphics/team-logo';
import { BestOddsTotalBlockDisplay, TotalBlockDisplay } from '../common/prediction-displays';
import { FilterMenu, SortByMenu } from '../../../../components/filter-menu';
import { SingleSelect } from '../../../../components/menus';
import {descendingSortFunction,ascendingSortFunction} from '../../../../utils/sorting';
import { ResponsiveSportsbookLogo } from '../../../../components/graphics/sportsbook-logo';
import { ExportPredictionsButton, ExportPredictionsPopup } from '../common/export-predictions';
import { ResponsiveIconStartButton } from '../../../../components/buttons';
import { WarningSnackbar } from '../../../../components/snackbars';
import { PROPS_WITHOUT_ODDS } from '../../../../config/models';
import { blobToShareData, filterPredictionsExportData, jsonToCsvBlob } from '../../../../utils/export';
import { availablePredictionExportDataFormats, defaultPredictionExportDataFormat } from '../../../../config/export';
import { toolbarDropdownHeight } from '../common/config';

function PlayerPredictionResultsFilter({
    prop,largeScreen,sportsbookSelection,setSportsbookSelection,
    predictionData,setFilteredPredictionsData
}){

    ////////////////////////////////////////////////////////
    // Filters
    // Note: Sportsbook filter is tied into the
    // table and can not be moved into this component
    ////////////////////////////////////////////////////////
    const [hidePlayersWithoutodds, setHidePlayersWithoutodds] = useState(
        !PROPS_WITHOUT_ODDS.includes(prop)
    );
    const oddsExistFilterFn = ({odds_exist}) => hidePlayersWithoutodds ? odds_exist : true;

    const [showValueBetsOnly,setShowValueBetsOnly] = useState(false);
    const valueFilterFn = ({predicted_odds_values}) => showValueBetsOnly ? predicted_odds_values.max_value > 0 : true;

    const availableTotalTypes = ["All","Over","Under"];
    const [totalType,setTotalType] = useState("All");
    const totalTypeFilterFn = ({predicted_odds,best_odds}) => {
        if (totalType === 'All') {
            return true;
        }
        else {
            if (totalType === 'Over') {
                const totalTypeFilter = 'over';
                return predicted_odds[totalTypeFilter].point > best_odds[totalTypeFilter].consensus
            } else {
                // Unders
                const totalTypeFilter = 'under';
                return predicted_odds[totalTypeFilter].point < best_odds[totalTypeFilter].consensus
            }
        }
    }

    // If columns change, we need to reapply filters
    useEffect(() => {
        if (predictionData.length !== 0) {
            let filteredData = predictionData.slice().filter(oddsExistFilterFn);
            filteredData = filteredData.filter(valueFilterFn);
            filteredData = filteredData.filter(totalTypeFilterFn);
            setFilteredPredictionsData(filteredData);
        }
     },[predictionData,hidePlayersWithoutodds,showValueBetsOnly,totalType]);

    return (
        <FilterMenu>

            {/* The below items are only shown on small screens */}
            {!largeScreen &&
            // Don't give users this option until the screen is smaller
            <MenuItem>
                <SingleSelect 
                label="Sportsbook"
                value={sportsbookSelection}
                availableValues={['best_odds'].concat(validSportsbooks.map(value => value.key))}
                availableValuesDisplay={['Best Odds'].concat(validSportsbooks.map(value => value.display))}
                onChangeFn={(event)=> setSportsbookSelection(event.target.value)}
                />
            </MenuItem>
            }

            {/* The below items are always shown in the filter */}
            <MenuItem>
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={hidePlayersWithoutodds}
                            onChange={(event) => setHidePlayersWithoutodds(event.target.checked)}
                            inputProps={{ 'aria-label': 'controlled' }}
                        />
                    }
                label="Only show players with odds"
                />
            </MenuItem>
            <MenuItem>
                <FormControlLabel
                    control={
                    <Checkbox
                        checked={showValueBetsOnly}
                        onChange={(event) => setShowValueBetsOnly(event.target.checked)}
                        inputProps={{ 'aria-label': 'controlled' }}
                    />
                    }
                label="Show value bets only"
                />
            </MenuItem>

            <MenuItem>
                <SingleSelect
                label="Bet Type"
                value={totalType}
                onChangeFn={(event) => setTotalType(event.target.value)}
                availableValues={availableTotalTypes}
                />
            </MenuItem>
        </FilterMenu>
    )
}

function PlayerPredictionResultsSortBy({filteredData,setFilteredData}){

        ////////////////////////////////////////////////////////
        // Sort functions
        ////////////////////////////////////////////////////////
    
        const options = [
            {
                title: "Highest Value",
                sortFn: (a,b) => 
                    descendingSortFunction(a.predicted_odds_values.max_value,b.predicted_odds_values.max_value)
            },
            {
                title: "Lowest Value",
                sortFn: (a,b) => 
                    ascendingSortFunction(a.predicted_odds_values.max_value,b.predicted_odds_values.max_value)
            },
        ];
        
        // Set selected index to null. Not sorting anything to start.
        const [selectedIndex,setSelectedIndex] = useState(null);
        
        const sortData = (sortFn) => {
            const localFilteredData = filteredData.slice().sort(sortFn);
            setFilteredData(localFilteredData);
        }

        const handleChange = (event) => {
            const index = event.currentTarget.value;
            sortData(options[index].sortFn);
            setSelectedIndex(index);
        }
    
        return (
            <SortByMenu
            selectedIndex={selectedIndex}
            options={options}
            onChangeFn={handleChange}
            />
        )
}

export function PlayerPredictionResultsTable({league,position,prop,date,predictionData,units,loading}){

    const theme = useTheme();
    const largeScreen = useMediaQuery(theme.breakpoints.up('md'));
    const screenTooSmallForShare = useMediaQuery('(max-width:350px)');

    const columnVisibilityDict = {
        "best_odds" : true,
        "fanduel"   : true,
        "draftkings" : true,
        "betmgm"    : true,
        "espnbet"    : true,
        "betrivers"    : true,
        "betonlineag"    : true,
        "bovada"     : true,
        "ballybet" : true,
        "hardrockbet" : true,
        "mybookieag" : true,
        "caesars"   : true,
        "betus"  : true,
        "lowvigag"  : true,
        "betparx"  : true,
    }

    ////////////////////////////////////////////////////////
    // States
    ////////////////////////////////////////////////////////

    // Share States - I know there is a lot
    const [tableRef,setTableRef] = useState(null);
    const [enableExport,setEnableExport] = useState(false);
    const [rowSelection,setRowSelection] = useState({});
    const [enableRowSelection,setEnableRowSelection] = useState(false);
    const [exportLoading,setExportLoading] = useState(false);
    const [sharePopupOpen,setSharePopupOpen] = useState(false);
    const [exportDataBlob,setExportDataBlob] = useState(null);
    const [warningSnackbarOpen,setWarningSnackbarOpen] = useState(false);
    const [preShareFilteredPredictionsData,setPreShareFilteredPredictionsData] = useState([]);
    const [exportDataFormat,setExportDataFormat] = useState(defaultPredictionExportDataFormat)

    const [filteredPredictionsData,setFilteredPredictionsData] = useState(predictionData);

    const [sportsbookSelection,setSportsbookSelection] = useState("best_odds");
    const [columnVisibility,setColumnVisibility] = useState(columnVisibilityDict);

    ////////////////////////////////////////////////////////
    // Column Definitions
    ////////////////////////////////////////////////////////
    const oddsColumnWidth = largeScreen ? DesktopOddsColumnWidth : MobileOddsColumnWidth;

    const columns = useMemo(
        () => [
          {
              // Set the row value for search, display is overriden by Cell below
              accessorFn: (row) => row.player.display_name,
              id: 'players',
              header: 'Players',
              size: largeScreen ? 200 : 150,
              enableSorting: false,
              enableColumnFilter: false,
              enableColumnPinning: true,
              Header: ({ column }) => (
                  <>
                      <Typography variant={largeScreen ? "body1" : "body2"}>
                          {dayjs(date).format('MMM Do, YYYY')}
                      </Typography>
                  </>
              ),
              Cell: ({ row }) =>
                  <>
                      <PlayerDisplay player={row.original.player} units={units} 
                      league={league} largeScreen={largeScreen}
                      />
                  </>
          },
          {
              accessorFn: (row) => row.predicted_odds, 
              id: 'predicted_prop',
              header: 'Prediction',
              size: oddsColumnWidth,
              enableSorting: false,
              enableColumnFilter: false,
              enableColumnPinning: true,
              Header: ({ column }) => (
                    <>
                        <Typography variant={largeScreen ? "h6" : "caption"}>{column.columnDef.header}</Typography>
                    </>
                ),
              Cell: ({ row }) =>
                  <>
                      <TotalBlockDisplay totals={row.original.predicted_odds}
                            // These have to be str indexed for some reason
                            overHasValue={row.original.predicted_odds_values.over['value_bool']}
                            underHasValue={row.original.predicted_odds_values.under['value_bool']}
                            debug={row.original.player.display_name}
                      />
                  </>
          },
          {
            accessorFn: (row) => row.best_odds, 
            id: 'best_odds',
            header: 'Best Odds',
            size: oddsColumnWidth,
            enableSorting: false,
            enableColumnFilter: false,
            enableColumnPinning: false,
            Header: ({ column }) => (
                  <>
                      <Typography variant={largeScreen ? "h6" : "caption"}>{column.columnDef.header}</Typography>
                  </>
              ),
            Cell: ({ row }) =>
                <>
                    <BestOddsTotalBlockDisplay best_odds_totals={row.original.best_odds} />
                </>
        },
          ...validSportsbooks.map((sportsbook,index) => (
            {
            accessorFn: (row) => row.sportsbooks[sportsbook.display], 
            id: sportsbook.key,
            header: sportsbook.key,
            enableSorting: false,
            enableColumnFilter: false,
            enableColumnPinning: false,
            size: oddsColumnWidth,
            Header: ({ column }) => (
                <>
                    <ResponsiveSportsbookLogo sportsbook={column.columnDef.header} desktopWidth={"100%"} mobileWidth={30} largeScreen={largeScreen} />
                </>
            ),
            Cell: ({ row }) => (
                <>
                    <TotalBlockDisplay totals={row.original.sportsbooks[sportsbook.display]} 
                    bestSportsbookOver={row.original.best_odds.over.sportsbooks.includes(sportsbook.display)}
                    bestSportsbookUnder={row.original.best_odds.under.sportsbooks.includes(sportsbook.display)}
                    debug={sportsbook.display+' '+row.original.player.display_name}
                    />
                </>
            )
            }
            ))
  
          ],
          [units,largeScreen]
    );

    const updateColumnVisibilityResponsive = () => {
        if (largeScreen) {
            setColumnVisibility(columnVisibilityDict);
        }
        else {
            let localColumnVisibility = columnVisibilityDict;
            for (const [key, value] of Object.entries(localColumnVisibility)) {
                if (!key.includes('predicted') && !key.includes(sportsbookSelection)){
                    localColumnVisibility[key] = false;
                }
            }
            setColumnVisibility(localColumnVisibility);
        }
    }

    useEffect(() => {
        updateColumnVisibilityResponsive();
    }, [largeScreen,sportsbookSelection]);

    ////////////////////////////////////////////////////////
    // Share functions
    ////////////////////////////////////////////////////////

    const setupExportData = (table) => {
        // Sets up table to allow users to select the rows they
        // want to export

        if (screenTooSmallForShare) {
            setWarningSnackbarOpen(true);
            return;
        }

        setTableRef(table);
        setEnableExport(true);
        setPreShareFilteredPredictionsData(filteredPredictionsData);
        setEnableRowSelection(true);
    }

    const exportData = async (shareData) => {
        // Only use Web Share API when mobile
        if (navigator.share !== null && !largeScreen) {
            try {
              await navigator
                .share(shareData)
                .then(() => {
                  setEnableRowSelection(true);
                  setFilteredPredictionsData(preShareFilteredPredictionsData);
                  setExportLoading(false);
              });
            } catch (error) {
              // Web Share Api exited with bad status. This occurs
              // when a share is cancelled though
              setEnableRowSelection(true);
              setFilteredPredictionsData(preShareFilteredPredictionsData);
              setExportLoading(false);
            }
        } else {
              // fallback code for unsupported browsers and desktop browsers
              setSharePopupOpen(true);
  
              setEnableRowSelection(true);
              setFilteredPredictionsData(preShareFilteredPredictionsData);
              setExportLoading(false);
        }

    }

    const sharePng = async (canvas) => {

        const imageUrl = canvas.toDataURL();
        const blob = await (await fetch(imageUrl)).blob();
        setExportDataBlob(blob);
        const shareData = blobToShareData(blob,'predictions.png');

        exportData(shareData);
        
    };

    const shareCsv = async (selectedRowIndexes) => {

        // Convert filtered predictions data into a csv
        let rowsToExport = filteredPredictionsData.slice().filter((_,index) => selectedRowIndexes.includes(index.toString()));

        // Remove some data before export to make data consistent length
        // and to protect some of the information from being easily exported.
        rowsToExport = filterPredictionsExportData(rowsToExport,false);

        const blob = jsonToCsvBlob(rowsToExport)
        setExportDataBlob(blob);
        const shareData = blobToShareData(blob,'predictions.csv');

        exportData(shareData);
    };

    const handleExportData = () => {
        // Do not try to export nothing
        if (Object.keys(rowSelection).length == 0) {
            return;
        }

        setExportLoading(true);

        // Set up table for canvas capture
        // Filter to show only selected rows and disable row selection to hide that column
        // and styling
        const selectedRowIndexes = Object.keys(rowSelection);
        setFilteredPredictionsData(filteredPredictionsData.slice().filter((_,index) => selectedRowIndexes.includes(index.toString())));
        setRowSelection({});
        setEnableRowSelection(false);

        switch (exportDataFormat) {
            case "png":
                // Wait while canvas setup is rendering
                setTimeout(() => {

                const ref = tableRef.refs.tableContainerRef.current;
                
                html2canvas(ref,{
                    height: document.outerHeight,
                    windowHeight: document.outerHeight,
                    scale: 5,
                    useCORS: true
                }).then(canvas => {
                    sharePng(canvas);
                });

                }, 1000);
                break;

            case "csv":
                shareCsv(selectedRowIndexes);
                break;

            default:
                // Should never occur
                break;

        }
        
    }

    const handleCancelExportData = () => {
        setRowSelection({});
        setEnableExport(false);
        setEnableRowSelection(false);
    }

    return (
        <>
        <Box component='div' sx={{width: largeScreen ? '90%' : null}}>
            <BasePredictionResultsMRT
                columns={columns}
                data={filteredPredictionsData}

                loading={loading}
                theme={theme}
                largeScreen={largeScreen}
                height={enableExport ? 'none' : tableHeight}

                // Top Toolbar custom actions
                renderTopToolbarCustomActions={({ table }) => {

                    return (
                    <Box component="div" sx={{ display: 'flex', gap: '0.5rem'}}>
                        <ExportPredictionsPopup 
                        open={sharePopupOpen} 
                        setOpen={setSharePopupOpen}
                        blob={exportDataBlob}
                        format={exportDataFormat}
                        />
                        <WarningSnackbar 
                        open={warningSnackbarOpen}
                        setOpen={setWarningSnackbarOpen}
                        message={"Screen too small to share predictions"} 
                        />
                        <Stack direction="row" spacing={1}>
                            {enableExport ?
                            <>
                            <LoadingButton
                            loading={exportLoading}
                            startIcon={<ShareIcon />}
                            onClick={handleExportData} 
                            variant="contained"
                            sx={{backgroundColor: "success.dark"}}
                            >
                                Share
                            </LoadingButton>
                            <SingleSelect
                            label="format"
                            value={exportDataFormat}
                            availableValues={availablePredictionExportDataFormats}
                            onChangeFn={(event) => setExportDataFormat(event.target.value)}
                            width={80}
                            height={toolbarDropdownHeight}
                            />
                            <ResponsiveIconStartButton
                            text="Cancel"
                            icon={<CloseIcon />}
                            onClick={handleCancelExportData} 
                            sx={{backgroundColor: "error.dark"}}
                            />
                            </>
                            :
                            <>
                            <PlayerPredictionResultsFilter
                                prop={prop}
                                largeScreen={largeScreen}
                                sportsbookSelection={sportsbookSelection}
                                setSportsbookSelection={setSportsbookSelection}
                                predictionData={predictionData}
                                setFilteredPredictionsData={setFilteredPredictionsData}
                            />
                            <PlayerPredictionResultsSortBy 
                                filteredData={filteredPredictionsData}
                                setFilteredData={setFilteredPredictionsData}
                            />
                            <ExportPredictionsButton setupExportData={() => setupExportData(table)} />
                            </>
                            }
                        </Stack>
                    </Box>
                    );
                }}

                state={{ columnVisibility, showSkeletons: loading, showGlobalFilter: true, rowSelection }}
            
                onColumnVisibilityChange={ setColumnVisibility }

                // Column Pinning
                enableColumnPinning={false}
                layoutMode='grid-no-grow'
                initialState= {{
                    columnPinning: { left: ['mrt-row-select','players','predicted_prop'] },
                    showGlobalFilter: true
                }}

                // Row selection
                enableRowSelection={enableRowSelection}
                onRowSelectionChange={setRowSelection}

            /> {/* End of table definition */}
        </Box>    
        </>
        )
}

function PlayerDisplay({player,units,league,largeScreen}) {

    const typographyVariant = largeScreen ? "body1": "body2";
    const logoWidth = largeScreen ? 25 : 20;

    return (
        <>
            <Stack direction="column" height="100%" justifyContent="center" spacing={1}>
                <Stack height="50%" justifyContent="center">

                        <Stack direction="column" justifyContent="center" spacing={1}>
                            <Stack direction="row" spacing={{xs:0.5,sm:1}} justifyContent="flex-start" alignItems="center">
                                <TeamLogo team={player.location+" "+player.nickname} league={league} width={logoWidth}/>
                                
                                <Typography variant={typographyVariant} >
                                    {largeScreen ? player.display_name : player.display_name_abbreviated}
                                </Typography>
                                    
                            </Stack>
                            <Stack direction="row" spacing={{xs:0.5,sm:1}} justifyContent="flex-start" alignItems="center">
                                <Box sx={{width: logoWidth}}></Box>
                                <Typography variant={typographyVariant}>
                                        {player.prediction + " " + units}
                                </Typography>
                            </Stack>
                        </Stack>
                        
                    
                </Stack>
            </Stack>
        </>
    )
}



