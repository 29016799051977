import {flatten} from 'flat';
import { playerPredictionKeysToExport, teamPredictionKeysToExport } from '../config/export';

export function jsonToCsvBlob(jsonData) {

    const flattenedData = jsonData.map(item => flatten(item));
    const headers = Object.keys(flattenedData[0]).join(',');
    const csvRows = flattenedData.map(item => Object.values(item).join(',')).join('\n');
    const csvData = `${headers}\n${csvRows}`;

    const contentType = 'text/csv';
    const blob = new Blob([csvData], {type: contentType});
    
    return blob;
}

export function blobToShareData(blob,filename){

    const filesArray = [
    new File(
        [blob],
        filename,
        {
        type: blob.type,
        lastModified: new Date().getTime()
        }
    )
    ];
    const shareData = {
        files: filesArray,
    };

    return shareData;
}

const baseNullOdds = {
    point: null,
    price: null
}

const spreadsNullOdds = {
    away : baseNullOdds,
    home: baseNullOdds
}
const totalsNullOdds = {
    over : baseNullOdds,
    under: baseNullOdds
}

const baseTeamNullOdds = {
    spreads: spreadsNullOdds,
    totals: totalsNullOdds
}

const basePlayerNullOdds = totalsNullOdds; // readability

export function filterPredictionsExportData(jsonData,isTeamData) {

    const keysToKeep = isTeamData ? teamPredictionKeysToExport : playerPredictionKeysToExport;


    // Copy keys we want from each dict in array
    const result = jsonData.map(obj => {
    const newObj = {};
    keysToKeep.forEach(key => {
        if (obj.hasOwnProperty(key)) {
        newObj[key] = obj[key];
        }
    });


    // Fill missing sportsbooks with null
    const sportsbookKeys = Object.keys(newObj.sportsbooks);
    const newSportsbooksObj = {};
    sportsbookKeys.forEach(key => {
        if (Object.keys(newObj.sportsbooks[key]).length === 0) {
            // Fill in empty books with null defaults to unify csv row length
            newSportsbooksObj[key] = isTeamData ? baseTeamNullOdds : basePlayerNullOdds;
        } else {
            newSportsbooksObj[key] = newObj.sportsbooks[key]
        }
    })
    newObj.sportsbooks = newSportsbooksObj;

    return newObj;
    });

    return result
}