
import ReactMarkdown from 'react-markdown';

export function GlossaryContent({glossaryContent}){


    return (
        <ReactMarkdown>
            {glossaryContent}
        </ReactMarkdown>
    )
}