
import {SportsFootball, SportsBasketball} from '@mui/icons-material';

export const team_leagues = [
    "NFL",
    "NBA",
    "NCAA-Football",
    "NCAA-Basketball"
]

export const player_leagues = [
    "NFL",
    "NBA"
]

export const leagueConfig = [
    { id: 1, name: 'NFL', target: 'NFL', player_enabled: true},
    { id: 2, name: 'NBA', target: 'NBA', player_enabled: true},
    { id: 3, name: 'NCAAF', target: 'NCAA-Football', player_enabled: false},
    { id: 4, name: 'NCAAB', target: 'NCAA-Basketball', player_enabled: false}
];

export function LeagueIcon(props) {

    const {league,...iconProps} = props;

    const map = {
        "NFL": <SportsFootball {...iconProps} />,
        "NCAA-Football": <SportsFootball {...iconProps} />,
        "NBA": <SportsBasketball {...iconProps}/>,
        "NCAA-Basketball": <SportsBasketball {...iconProps} />
    }

    return map[league]
}

export const predictionWarningMonthsByLeague = {
    // Months are zero indexed so January = 0 to be used
    // to compared to dayjs().month()
    "NFL"               : [
        9
    ],
    "NBA"               : [
        10
    ],
    "NCAA-Football"     : [
        9
    ],
    "NCAA-Basketball"   : [
        10
    ]
}

export const leagueToGlossaryIdMap = {
    'NFL' : 1,
    'NCAA-Football' : 2,
    'NBA' : 3,
    'NCAA-Basketball' : 4,

}