import {useState} from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import {
    Box,
    Button,
    Container,
    Skeleton,
    Stack,
    Toolbar
} from '@mui/material';

import StackedLineChartIcon from '@mui/icons-material/StackedLineChart';
import PaymentIcon from '@mui/icons-material/Payment';
import ForumIcon from '@mui/icons-material/Forum';
import AccountCircle from '@mui/icons-material/AccountCircle';
import InsightsIcon from '@mui/icons-material/Insights';
import DescriptionIcon from '@mui/icons-material/Description';
import ArticleIcon from '@mui/icons-material/Article';
import BusinessIcon from '@mui/icons-material/Business';
import LanguageIcon from '@mui/icons-material/Language';

import {SportsFootball, SportsBasketball} from '@mui/icons-material';
import { SolvedSportsLogo } from '../../../components/graphics/solvedsports-logo';

import { ProfileMenu, DesktopNavBar, MobileNavBar } from './menus';
import PaymentOptionsPopup from '../../pricing/components/payment-popup';

import { useAuth } from '../../../hooks/auth';

export const navbarHeight = 110;

export let profileNavItems = [
    {
    'text': 'Account',
    'icon': <AccountCircle />
    },
    {
    'text': 'Team Models',
    'icon': <StackedLineChartIcon />
    },
    {
    'text': 'Player Models',
    'icon': <InsightsIcon />
    },
    {
    'text': 'Payments',
    'icon': <PaymentIcon />
    }
];

export function Navbar() {

    const theme = useTheme();
    const navigate = useNavigate();
    const location = useLocation();

    const largeScreen = useMediaQuery(theme.breakpoints.up('md'));

    const [pricingPopupOpen, setPricingPopupOpen] = useState(false);

    function handlePricingClicked(){
        setPricingPopupOpen(true);
    }

    function handleProfileMenuItemClicked(selectedTab){
        navigate('/profile',
            {state : {selectedTab : selectedTab}}
        )
        if (location.pathname==='/profile')
        {
            // Refresh if already at /profile
            navigate(0);
        }
        
    }

    const NavBarItems = [
        {
            'text': 'Pricing',
            'icon': <PaymentIcon />,
            'onClick': null,
            'link': '/pricing',
            'items': []  
        },
        {
            'text': 'Team Model',
            'icon': <StackedLineChartIcon />,
            'onClick': null,
            'link': null,
            'items': [
                {
                'text': 'NFL',
                'icon': <SportsFootball />,
                'onClick': null,
                'link': '/create-team-model/NFL',
                'items' : []
                },
                {
                'text': 'NBA',
                'icon': <SportsBasketball />,
                'onClick': null,
                'link': '/create-team-model/NBA',
                'items' : []
                },
                {
                'text': 'NCAAF',
                'icon': <SportsFootball />,
                'onClick': null,
                'link': '/create-team-model/NCAA-Football',
                'items' : []
                },
                {
                'text': 'NCAAB',
                'icon': <SportsBasketball />,
                'onClick': null,
                'link': '/create-team-model/NCAA-Basketball',
                'items' : []
                }
            ]
        },
        {
            'text': 'Player Model',
            'icon': <InsightsIcon />,
            'onClick': null,
            'link': null,
            'items': [
                {
                'text': 'NFL',
                'icon': <SportsFootball />,
                'onClick': null,
                'link': '/create-player-model/NFL',
                'items' : []
                },
                {
                'text': 'NBA',
                'icon': <SportsBasketball />,
                'onClick': null,
                'link': '/create-player-model/NBA',
                'items' : []
                }
            ]
        },
        {
            'text': 'Blog',
            'icon': <ArticleIcon />,
            'onClick': null,
            'link': '/blog',
            'items': []  
        },
        {
            'text': 'Company',
            'icon': <BusinessIcon />,
            'onClick': null,
            'link': null,
            'items': [
                {
                    'text': 'Changelog',
                    'icon': <DescriptionIcon />,
                    'onClick': null,
                    'link': '/changelog',
                    'items': []  
                },
                {
                    'text': 'Forum',
                    'icon': <ForumIcon />,
                    'onClick': null,
                    'link': '/forum',
                    'items': []  
                },
                {
                    'text': 'Glossary',
                    'icon': <LanguageIcon />,
                    'onClick': null,
                    'link': '/glossary/NFL',
                    'items': []  
                },
            ]  
        },
        
    ]

    // Iterate over profile nav items defined as export and add onClick 
    // functions because they can only be defined inside a component

    for (let i = 0; i < profileNavItems.length; i++) {
        profileNavItems[i]['onClick'] = () => handleProfileMenuItemClicked(profileNavItems[i]['text']);
    }

    return (
        <Box sx={{borderBottom : 2, height: navbarHeight, borderColor: `${theme.palette.background.paper}`}}>
        <Container maxWidth="xl" disableGutters sx={{height: '100%'}}>
        <Toolbar sx={{height: "100%"}}>

            {/* Mobile Drawer Navbar */}
            {!largeScreen && 
                <MobileNavBar navBarItems={NavBarItems} />
            }

            {/* Logo with link to home */}
            <Box component="div" sx={{
                flexGrow: largeScreen ? 0 : 1,
                display: 'flex',
                justifyContent: 'center'
            }}>
            <Link to='/' style={{ textDecoration: 'none', color: 'inherit' }}>
                <SolvedSportsLogo sx={{height: navbarHeight * 0.80, width: navbarHeight * 0.80}} />
            </Link>
            </Box>

            {/* Spacing buffer in middle */}
            {largeScreen && 
            <Box sx={{ flexGrow: 1, display: {m:'none',l:'inline-flex'}, justifyContent: 'end',mr: 2}}>
                <DesktopNavBar navBarItems={NavBarItems} />
            </Box>
            }
            {/* Profile or Login Button */}
            <Box>
                <ProfileOrLogin profileNavItems={profileNavItems} />
            </Box>
        </Toolbar>
        
        {/* 
        CURRENTLY UNUSED, Pricing button in nav bar goes to /pricing page now
        but keep this here in case we revert. To use popup instead of redirect,
        set onClick to handlePricingClicked function and set link to null in
        navBarItems
        */}
        <PaymentOptionsPopup open={pricingPopupOpen} setOpen={setPricingPopupOpen}/>
        </Container>
        </Box>
    );
}

function ProfileOrLogin({profileNavItems}){

    const auth = useAuth();

    const loadingCircleDims = Math.round(navbarHeight/2);

    return (
        <>
            {/* 
            Display loading animation while waiting for user authenticated state
            to be determined
            */}
            {auth.loading ?
            (<>
                <Skeleton variant="circular" width={loadingCircleDims} height={loadingCircleDims}/>
            </>)
            :
            (<>

            {/* 
            Once done loading, show the profile menu if authenticated or a sign
            in button if not.
            */}
            {auth.authenticated ? (
                <>
                    <Stack direction="row" spacing={2} alignItems="center" >
                        <ProfileMenu profileNavItems={profileNavItems} />
                    </Stack>
                    
                </>

            ) : (
                <>
                    <Link to ='/login'>
                            <Button variant="contained">Sign in</Button>
                    </Link>
                </>
            )}
            </>)
            }
        </>
    );
}