
import {
    Checkbox,
    InputLabel,
    FormControl,
    ListItemIcon,
    ListItemText,
    Menu,
    MenuItem,
    OutlinedInput,
    Select,
} from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';

import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

export function DropdownMenu(props){

    return (
        <Menu
        MenuListProps={{
        'aria-labelledby': 'basic-button',
        }}
        anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
        }}
        transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
        }}
        {...props}
        >
            {props.children}
        </Menu>
    )
}

const FILTER_MENU_ITEM_HEIGHT = 48;
const FILTER_MENU_ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: FILTER_MENU_ITEM_HEIGHT * 4.5 + FILTER_MENU_ITEM_PADDING_TOP,
        width: 200,
      },
    },
};
const defaultDropdownWidth = 250;

export function MultiSelect({label,values,setValues,availableValues}){
    
    const inputId = "input-"+label;

    return (
        <FormControl sx={{ m: 1, width: defaultDropdownWidth}}>
            <InputLabel id={inputId}>{label}</InputLabel>
            <Select
            labelId={inputId}
            id={`${label}-multiple-checkbox`}
            multiple
            value={values}
            onChange={(event)=>setValues(event.target.value)}
            input={<OutlinedInput label={label} />}
            renderValue={(selected) => selected.join(', ')}
            // native={isMobile}
            MenuProps={MenuProps}
            >
            {availableValues.map((item) => (
                <MenuItem key={item} value={item}>
                <Checkbox checked={values.indexOf(item) > -1} />
                <ListItemText primary={item} />
                </MenuItem>
            ))}
            </Select>
        </FormControl>
    )
}

export function SingleSelect({label,value,availableValues,onChangeFn,availableValuesDisplay=null,width=null,enabled=true,height=null}){
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));


    // Ensure available values display exists and is the same length as the available values
    // If not, set the display to be the values
    if (availableValuesDisplay === null || availableValuesDisplay.length !== availableValues.length) {
        availableValuesDisplay =availableValues;
    }
    const elementWidth = width ? width : defaultDropdownWidth;

    const inputId = "input-"+label;

    return (
        <FormControl sx={{ m: 0, width: elementWidth, height: height }} disabled={!enabled}>
            <InputLabel id={inputId}>{label}</InputLabel>
            <Select
            labelId={inputId}
            id={`${label}-select`}
            value={value}
            onChange={onChangeFn}
            input={<OutlinedInput label={label} />}
            renderValue={(selected) => availableValuesDisplay[availableValues.indexOf(selected)]}
            // native={isMobile}
            MenuProps={MenuProps}
            sx={{height: '100%'}}
            >
            {availableValues.map((item,index) => (
                <MenuItem key={item} value={item}>
                <ListItemText primary={availableValuesDisplay[index]} />
                {value === item &&
                <ListItemIcon sx={{justifyContent: 'flex-end'}}>
                    <CheckIcon fontSize="small" color="success"/>
                </ListItemIcon>
                }
                </MenuItem>
            ))}
            </Select>
        </FormControl>
    )
}