
const images = require.context('../../assets/sportsbook-logos', false, /\.png$/);

export function SportsbookLogo({sportsbook,width=25}){

    const formattedSportsbook= sportsbook.toLowerCase().replace('.ag','').replace(' (us)','');

    const logoFile = "./"+formattedSportsbook+"-desktop.png";

    // Try to load the image using require() but handle if it does not exist
    let logoSrc;
    try {
        logoSrc = images(logoFile);
    }
    catch {
        logoSrc = null;
    }

    return (
        <>
            <img src={logoSrc} 
            alt=""
            width={width}
            overflow="clip"
            />
        </>
    );
}

export function MobileSportsbookLogo({sportsbook,width=20}){
    const formattedSportsbook = sportsbook.toLowerCase().replaceAll('.','').replaceAll(' ','');

    const logoFile = "./"+formattedSportsbook+"-mobile.png";

    // Try to load the image using require() but handle if it does not exist
    let logoSrc;
    try {
        logoSrc = images(logoFile);
    }
    catch {
        logoSrc = null;
    }

    return (
        <>
            <img src={logoSrc} 
            alt=""
            width={width}
            overflow="clip"
            />
        </>
    );
}

export function ResponsiveSportsbookLogo({sportsbook,desktopWidth,mobileWidth,largeScreen}){

    return (
        <>
        {largeScreen ?
        <SportsbookLogo sportsbook={sportsbook} width={desktopWidth} />
        :
        <MobileSportsbookLogo sportsbook={sportsbook} width={mobileWidth} />
        }
        </>
    )
}