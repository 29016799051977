
import { useState } from 'react';
import { useTheme, } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import EastIcon from '@mui/icons-material/East';
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import OpenInNewIcon from '@mui/icons-material/OpenInNew';

import {
    Button,
    IconButton,
    InputAdornment,
    Link,
    Tooltip,
    Zoom
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';

import InfoIcon from '@mui/icons-material/Info';

export function SlimLink(props){

    return (
        <Link underline="none" variant="body2" {...props}/>
    )
}

export function ActionButton(props){

    return (
        <Button size="small" {...props}>
            {props.children}
        </Button>
    )
}

export function HiddenArrowButton({text,width="100%"}) {

    const [showArrow,setShowArrow] = useState(false);

    return (
        <>
            <Button
            sx={{ width:width}}
            onMouseEnter={() => {setShowArrow(true)}}
            onMouseLeave={() => {setShowArrow(false)}}
        >
            {text}
            {showArrow && <EastIcon sx={{ml: 1}}/>}
            
        </Button>
        </>
    )
}

export function TogglePasswordAdornment({showPassword,setShowPassword,disabled=false}){

    return (
        <InputAdornment position="end">
            <IconButton
            aria-label="toggle password visibility"
            onClick={()=>{setShowPassword(!showPassword)}}
            onMouseDown={()=>{setShowPassword(!showPassword)}}
            edge="end"
            disabled={disabled}
            >
            {showPassword ? <VisibilityOff /> : <Visibility />}
            </IconButton>
        </InputAdornment>

    )
}

export function DefaultTooltip({text}){

    return (
        <Tooltip
        title={text}
        TransitionComponent={Zoom}
        arrow
        
        // The below props make tooltips work on mobile
        // leaveDouchDelay specifies the default duration that
        // the tooltip will be open for on click on mobile in ms
        enterTouchDelay={0}
        leaveTouchDelay={8000}
        >
            <IconButton sx={{height: 30, width: 30}}>
                <InfoIcon fontSize="medium"/>
            </IconButton>
        </Tooltip>
    )
}

export function GenericTooltip(props){

    const {children,...otherProps} = props;

    return (
        <Tooltip
        TransitionComponent={Zoom}
        arrow
        
        // The below props make tooltips work on mobile
        // leaveDouchDelay specifies the default duration that
        // the tooltip will be open for on click on mobile in ms
        enterTouchDelay={0}
        leaveTouchDelay={8000}
        {...otherProps}
        >
            {children}
        </Tooltip>
    )
}

export function HyperparametersTutorialButton(){

    return (
        <Button href="/blog/32f17877-55aa-4d70-b446-e142c31ea439" target="_blank" endIcon={<OpenInNewIcon />}>
            Tutorial
        </Button>
    )
}

export function ResponsiveIconStartButton({text,icon,...props}){

    const theme = useTheme();
    const largeScreen = useMediaQuery(theme.breakpoints.up('md'));

    return (
        <LoadingButton
            variant="contained"
            startIcon={largeScreen && icon}
            {...props}
        >
            {largeScreen ? text : icon}
        </LoadingButton>
    )
}